<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-form :inline="true">
          <el-form-item label='通知类型:'>
            <el-select v-model="filter.mode" placeholder="请选择">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label='通知方式:'>
            <el-select v-model="filter.methods" placeholder="请选择">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label='发送状态:'>
            <el-select v-model="filter.status" placeholder="请选择">
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label='关键字:'>
            <el-input placeholder="通知名称" v-model='filter.info' clearable @clear='clearContent'></el-input>
          </el-form-item>
          <el-form-item>
              <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 表格主体 -->
    <el-table
        v-loading='listLoading'
        :data="tableData"
        :cell-style="rowClass"
        :header-cell-style='headerClass'
        border
        highlight-current-row
        @current-change='currentChange'
        tooltip-effect='dark'
        style="width: 100%"> 
        <el-table-column type='index' label='序号' width='60'></el-table-column>
        <el-table-column prop="CreateUserName" label="发送人"></el-table-column>
        <el-table-column prop="Name" label="通知名称"></el-table-column>
        <el-table-column prop="NoticeType" label="通知类型">
          <template slot-scope='scope'>
            <span v-if='scope.row.NoticeType == "0"'>持证监管</span>
            <span v-if='scope.row.NoticeType == "1"'>考试</span>
            <span v-if='scope.row.NoticeType == "2"'>学习</span>
            <span v-if='scope.row.NoticeType == "3"'>宣传推广</span>
            <span v-if='scope.row.NoticeType == "4"'>其他</span>
          </template>
        </el-table-column>
        <el-table-column prop="StartTime" label="通知开始发送时间">
          <template slot-scope='scope'>
            {{dateFormat('YYYY-mm-dd HH:MM:SS',scope.row.StartTime)}}
          </template>
        </el-table-column>
        <el-table-column prop="NoticeWay" label="通知方式">
          <template slot-scope='scope'>
            <span v-if='scope.row.NoticeWay == "0"'>短信</span>
            <span v-if='scope.row.NoticeWay == "1"'>语音</span>
            <span v-if='scope.row.NoticeWay == "2"'>站内信</span>
          </template>
        </el-table-column>
        <el-table-column prop="Status" label="通知状态">
            <template slot-scope='scope'>
              <span v-if='scope.row.Status == "0"'>未发送</span>
              <span v-if='scope.row.Status == "1"'>发送中</span>
              <span v-if='scope.row.Status == "2"'>已完成</span>
            </template>
        </el-table-column>
        <el-table-column prop="Content" label="通知内容" show-overflow-tooltip></el-table-column>
        <el-table-column prop="Result" label="通知结果">
            <template slot-scope='scope'>
               <router-link :to="{path:'/EnterNoticeResult',query:{id:scope.row.ID}}" style='color:red;'>{{scope.row.Result}}</router-link>
            </template>
        </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pages.PageIndex"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pages.PageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pages.DataCount"
      class='pageBar'>
    </el-pagination>
  </div>
</template>

<script>
import util from "../../../util/date";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import { getNoticeRecordListPage } from '@/api/api'
export default {
  components:{Toolbar},
  data() {
    return {
        filter:{
            mode:'',
            methods:'',
            status:'',
            info:''
        },
        buttonList:[],
        options1:[
            {
                label:'持证监管',
                value:0
            },
            {
                label:'考试',
                value:1
            },
            {
                label:'学习',
                value:2
            },
            {
                label:'宣传推广',
                value:3
            },
            {
                label:'其他',
                value:4
            }
        ],
        options2:[
            {
                label:'短信',
                value:0
            },
            {
                label:'语音',
                value:1
            },
            {
                label:'站内信',
                value:2
            }
        ],
        options3:[
            {
                label:'未发送',
                value:0
            },
            {
                label:'发送中',
                value:1
            },
            {
                label:'已完成',
                value:2
            }
        ],
        tableData:[],
        currentRow:null,
        pages:{
          PageIndex:1,
          PageSize:20,
          DataCount:0
        },
        listLoading:false
    };
  },
  methods: {
      //   表格样式处理
      rowClass ({columnIndex}) {
          if(columnIndex == 6){
              return 'color:#086eff;text-align:center;'
          }
          return 'padding:8px 0!important;text-align:center;'
      },
      headerClass(){
        return 'text-align:center;'
      },
      //   表格文字溢出处理
      formatter(row){
          var txt = row.content
          if(!txt)return '';
          if(txt.length > 11){
              return txt.slice(0,11) + '...';
          }
          return txt
      },
      // 获取当前行数据
      currentChange(row){
          this.currentRow = JSON.parse(JSON.stringify(row))
      },
      //   点击展示通知内容
      // showNoticeContent(row,cell){
      //     if(cell.property == 'content'){
      //       this.noticeContent = row.content
      //       this.dialogVisible = true
      //     }
      // },
      // 分页处理
      handleSizeChange(value){
          this.pages.PageSize = value
          this.getData()
      },
      handleCurrentChange(value){
          this.pages.PageIndex = value
          this.getData()
      },
      // 筛选内容清空，重新获取数据
      clearContent(){
          this.getData()
      },
      // 查询
      getNoticeRecordListPage(){
          this.getData()
      },
      //   获取页面数据
      getData(){
          this.listLoading = true
          var params = {
            name:this.filter.info,
            noticeWay:this.filter.methods,
            status:this.filter.status,
            noticeType:this.filter.mode,
            pageIndex:this.pages.PageIndex,
            pageSize:this.pages.PageSize,
          }
          getNoticeRecordListPage(params).then(res => {
              if(res.data.Success){
                this.listLoading = false
                this.tableData = res.data.Response.Data
                this.pages.DataCount = res.data.Response.DataCount
              }else{
                this.listLoading = false
                this.$message.error(res.data.Message)
              }
          })
      },
      callFunction(item) {
          this[item.Func].apply(this, item);
      },
      // 时间转换
      dateFormat(format,date){
            let ret = ''
            date = new Date(date)
            const option = {
                'Y+':date.getFullYear().toString(),  //
                'm+':(date.getMonth() + 1).toString(),
                'd+':date.getDate().toString(),
                'H+':date.getHours().toString(),
                'M+':date.getMinutes().toString(),
                'S+':date.getSeconds().toString()
            }
            for(let k in option){
                ret = new RegExp('('+k+')').exec(format)
                if(ret){
                    format = format.replace(
                        ret[1],
                        ret[1].length == 1? option[k] :option[k].padStart(ret[1].length,'0')
                    )
                }
            }
            return format
      }
  },
  created() {
    this.getData()
  },
  mounted(){
      let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
      this.buttonList = getButtonList(this.$route.path, routers);
  }
};
</script>

<style lang="stylus" scoped>
  // .pageBar{
  //   margin-top:15px!important;
  // }
</style>